import React, {
    useState,
    useEffect,
    useCallback
} from "react";
import axios from 'axios'
import Config from "../Config";
import { useParams } from "react-router-dom";
import { Container, Header, Loader, Card, Button, Grid, Divider, Segment, CardGroup, Label, Icon, Modal, Image } from 'semantic-ui-react';
import { useAuth0 } from "@auth0/auth0-react";
import StateLabel from "../components/StateLabel";
import DemoTypeLabel from "../components/DemoTypeLabel";
import ErrorMessage from "../components/ErrorMessage";
import IdpTypeGraphic from "../components/IdpTypeGraphic";
import { useNavigate, useLocation } from "react-router";
import NavigationButton from "../components/NavigationButton";
import { useFlags } from 'flagsmith/react';
import OpportunityDetails from "../components/OpportunityDetails";
import { getDemonstration, linkDemonstrationToOpp, getApplicationInstance, getResourceInstance, setDemonstrationToOpportunity } from "../services/DemoAPI";
import OneClickGuide from "../components/OneClickGuide"
import IdpType from "../components/IdpType";
import CollaboratorList from "../components/collaboration/CollaboratorList";
import CollaborationModal from "../components/collaboration/CollaborationModal";
import { useDemoContext } from "../DemoContext";
import { DemonstrationLabel } from "../components/DemonstrationLabel";
import { BreadcrumbList, MenuButton, MenuItem } from "@okta/odyssey-react-mui";
import LaunchButton from "../components/ui/LaunchButton";
import flagsmith from "flagsmith";

const DemoDetails = () => {
    let params = useParams();
    const demoContext = useDemoContext();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [demo, setDemo] = useState()
    const [idp, setIDP] = useState()
    const [error, setError] = useState(null)
    const [waiting, setWaiting] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const flags = useFlags(['resources', 'insights_static_resource', 'mobile_experience_portal_beta_static_app', 'one_click_guide_page', 'collaboration']);

    const getDemo = useCallback(async () => {
        setError()
        if (isAuthenticated) {
            getDemonstration(await getAccessTokenSilently(), params.demoName)
                .then(async (response) => {
                    for (let index = 0; index < response.data.app_instances.length; index++) {
                        const element = response.data.app_instances[index];
                        response.data.app_instances[index] = (await getApplicationInstance(await getAccessTokenSilently(), params.demoName, element.applicationId)).data
                    }

                    for (let index = 0; index < response.data.resource_instances.length; index++) {
                        const element = response.data.resource_instances[index];
                        response.data.resource_instances[index] = (await getResourceInstance(await getAccessTokenSilently(), params.demoName, element.resourceId)).data
                    }
                    response.data.app_instances.sort((a, b) => a.label.localeCompare(b.label));
                    setDemo(response.data);
                })
                .catch((error) => { setError(error); })
        } else {
            setDemo();
        }
    }, [getAccessTokenSilently, isAuthenticated, setDemo, params.demoName])

    const getIdp = useCallback(async () => {
        if (isAuthenticated) {
            if (demo) {
                axios.get(Config.resourceServer.demoAPI + "/idp/" + demo.idp_id,
                    {
                        headers: { Authorization: "Bearer " + await getAccessTokenSilently() }
                    }
                )
                    .then(response => { setIDP(response.data) })
                    .catch(err => { setError(err) })
            }
        }
        else {
            setDemo();
        }
    }, [demo, getAccessTokenSilently, isAuthenticated])

    //handles incoming idp and demo info when routed from a create
    useEffect(() => {
        if (state?.idp) {
            setIDP(state.idp)
        }
        if (state?.demo) {
            setDemo(state.demo)
        }
    }, [state])

    useEffect(() => {
        if (!idp) {
            getIdp()
        }
        if (idp && idp.state !== "active") {
            var handle = (setTimeout(() => getIdp(), 5000))
            return () => {
                clearTimeout(handle)
            }
        }
    }, [idp, getIdp])

    useEffect(() => {
        if (!demo) {
            getDemo()
        }
        if (demo && (demo.state !== "active" && demo.state !== "error")) {
            var handle = (setTimeout(() => getDemo(), 5000))
            return () => {
                clearTimeout(handle)
            }
        }
    }, [demo, getDemo, getAccessTokenSilently])

    async function deleteDemo() {
        setWaiting(true)
        axios.delete(Config.resourceServer.demoAPI + `/demonstration/${demo.name}`,
            {
                headers: { Authorization: "Bearer " + await getAccessTokenSilently() }
            })
            .then((response) => { demoContext.refreshContext(); navigate('/'); })
            .catch((error) => { setError(error); setWaiting(false) })
    }
    async function linkOpportunity(oppId) {
        setWaiting(true)
        linkDemonstrationToOpp(await getAccessTokenSilently(), demo.name, oppId)
            .then((response) => {
                response.data.app_instances.sort((a, b) => a.label.localeCompare(b.label));
                setDemo(response.data);
                setWaiting(false)
            })
            .catch((err) => {
                setWaiting(false)
            })
    }

    function handleOnClick(path) {
        navigate(path)
    }


    return (
        <Container className="appComponent">
            <BreadcrumbList>
                <NavigationButton destination={`/`} msg="Demos" />
                <i href="">
                    {demo ? demo.label : params.demoName}
                </i>
            </BreadcrumbList>
            <Segment>
                {demo && idp ? (
                    <Container>

                        <Grid stackable>
                            <Grid.Row columns={2}>
                                <Grid.Column width={13}>
                                    <DemonstrationLabel demo={demo} />
                                    <CollaborationModal
                                        entity={demo}
                                        entityType='demonstration'
                                        trigger={<CollaboratorList entity={demo} includeManage={flags.collaboration?.enabled} />} function_reloaddata={getDemo}>
                                    </CollaborationModal>
                                </Grid.Column>
                                <Grid.Column width={3} textAlign="right">
                                    {waiting ? (<Loader active inline />) : null}
                                    {<Modal
                                        basic
                                        onClose={() => setDeleteModalOpen(false)}
                                        onOpen={() => setDeleteModalOpen(true)}
                                        open={deleteModalOpen}
                                        size='small'
                                        trigger={
                                            <MenuButton
                                                buttonLabel="Manage"
                                                buttonVariant="secondary"
                                                menuAlignment="right"
                                                isDisabled={waiting || (demo.state !== 'active' && demo.state !== 'error') || idp.state === 'queued'}
                                            >
                                                {flagsmith.getTrait("connection") === "employee" && demo.type !== 'opportunity' &&
                                                    <MenuItem onClick={() => {
                                                        setWaiting(true);
                                                        getAccessTokenSilently()
                                                            .then((at) =>
                                                                setDemonstrationToOpportunity(at, demo.name)
                                                                    .then((response) => { setDemo(response.data); demoContext.refreshContext(); setWaiting(false) }
                                                                    )
                                                            )
                                                    }}>
                                                        Link Opportunity
                                                    </MenuItem>
                                                }
                                                <MenuItem onClick={() => setDeleteModalOpen(true)}>
                                                    Delete
                                                </MenuItem>
                                            </MenuButton>}
                                    >
                                        <Header icon>
                                            <Icon name='trash' />
                                            Are you sure you want to delete {demo.name}?
                                        </Header>
                                        <Modal.Content>
                                            This action is permanent and can’t be undone.
                                            {idp && idp.type === 'customer-identity' ? (
                                                <div>If the identity solution was provisioned by the demo platform it may be destroyed.</div>) : null
                                            }
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button color='red' onClick={() => setDeleteModalOpen(false)}>
                                                <Icon name='remove' /> Cancel
                                            </Button>
                                            <Button color='green' onClick={() => { setDeleteModalOpen(false); deleteDemo() }}>
                                                <Icon name='checkmark' /> Delete
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>}

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Header.Subheader className="meta">
                            {demo.type === 'opportunity' ? (
                                <OpportunityDetails linkedOpportunities={demo.linked_opportunities} function_linkOpportunity={linkOpportunity} allowEdit={!waiting} />
                            )
                                : (<DemoTypeLabel type={demo.type} />)}
                        </Header.Subheader>
                        <Divider className="noTopMargin" />
                    </Container>
                ) : (
                    <Header className="contentHeader">Demonstration: {params.demoName}</Header>
                )
                }
                {error ? (
                    <ErrorMessage error={error} retryAction={getDemo} />
                ) : null}
                {demo ? (
                    <Container>
                        {flags.one_click_guide_page.enabled && demo.meta_data.creation_source && demo.meta_data.creation_source !== 'builder' ? (
                            <OneClickGuide demo={demo} idp={idp} />
                        ) : (
                            <Container>
                                <Header as='h2'>Identity Cloud</Header>
                                <Container>
                                    {idp ? (
                                        <Card>
                                            <Card.Content>
                                                <Card.Header as='h4'><IdpType idp={idp} /></Card.Header>

                                                <IdpTypeGraphic type={idp.type} />
                                                <Card.Meta>
                                                    <StateLabel state={idp.state} />
                                                    {idp.type === 'customer-identity' && idp.invite_link &&
                                                        <div>
                                                            {idp.meta_data.created_by === user.email ? (<span>Be sure to 'Accept Invitation' before launching your tenant.</span>) : (<span>Ensure you have been invited to this tenant.</span>)}
                                                        </div>
                                                    }
                                                </Card.Meta>
                                            </Card.Content>
                                            <Card.Content extra>
                                                <Button className="branded" onClick={() => window.open(idp.dashboard)} disabled={idp.state !== "active"}>
                                                    Launch
                                                </Button>
                                                {idp.type === 'customer-identity' && idp.invite_link && idp.meta_data.created_by === user.email &&
                                                    <Button className="branded" onClick={() => window.open(idp.invite_link)}>
                                                        Accept Invitation
                                                    </Button>
                                                }
                                            </Card.Content>
                                        </Card>
                                    )
                                        : (
                                            <Loader active inline />
                                        )}

                                </Container>

                                <Header as='h2'>Applications</Header>
                                <Button disabled={demo.state !== 'active'} className="createActionButton branded" content='Add application' icon='plus' labelPosition='left' onClick={() => handleOnClick(`/demo/${demo.name}/apps`)} />
                                <Card.Group>
                                    {demo.app_instances.length !== 0 &&
                                        [...demo.app_instances].sort((a, b) => a.label.localeCompare(b.label)).map((app) =>
                                            <Card key={app.label}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        {app.label}{app.logo && <Image floated="right" size='mini' src={app.logo} />}
                                                        <span className="ComponentMetaRow">
                                                            {app.documentationLink && <a target="_blank" rel="noreferrer" href={app.documentationLink}>Documentation</a>}
                                                            {app.video && <a target="_blank" rel="noreferrer" href={app.video}>Video</a>}
                                                            {app.supportLink && <a target="_blank" rel="noreferrer" href={app.supportLink}>Support</a>}
                                                        </span>
                                                    </Card.Header>
                                                    <Card.Meta >
                                                        <StateLabel state={app.state} />
                                                    </Card.Meta>
                                                </Card.Content>
                                                <Card.Content extra>
                                                    <div className='ui two buttons'>
                                                        <LaunchButton url={app.launchUrl ? app.launchUrl : app.link} />
                                                        <Button className="" onClick={() => handleOnClick(`/demo/${demo.name}/apps/${app.applicationId}`)}>
                                                            Manage
                                                        </Button>
                                                    </div>
                                                </Card.Content>
                                            </Card>
                                        )
                                    }
                                    {flags.mobile_experience_portal_beta_static_app.enabled &&
                                        <Card key='Appetize'>
                                            <Card.Content>
                                                <Card.Header>Mobile Experience Portal</Card.Header>
                                                <Card.Meta>
                                                    Provides an emulated mobile experience that can be shared in your browser.
                                                </Card.Meta>
                                                <Card.Meta>
                                                    <Label >
                                                        <Icon name='flask' /> Beta
                                                    </Label>
                                                </Card.Meta>
                                            </Card.Content>
                                            <Card.Content extra>
                                                <div className='ui two buttons'>
                                                    <Button className="branded" onClick={() => window.open('https://mobile.oktademo.engineering/')}>
                                                        Launch
                                                    </Button>
                                                </div>
                                            </Card.Content>
                                        </Card>
                                    }
                                </Card.Group>

                                {(flags.resources.enabled || flags.insights_static_resource.enabled) &&
                                    <Header as='h2'>Resources</Header>
                                }

                                {flags.resources.enabled &&
                                    <Button disabled={demo.state !== 'active'} className="createActionButton branded" content='Add resource' icon='plus' labelPosition='left' onClick={() => handleOnClick(`/demo/${demo.name}/resources`)} />
                                }

                                {(flags.resources.enabled || flags.insights_static_resource.enabled) &&
                                    <CardGroup>

                                        {flags.resources.enabled && demo.resource_instances.length !== 0 && [...demo.resource_instances].sort((a, b) => a.label.localeCompare(b.label)).map((resource) =>
                                            <Card key={resource.label}>
                                                <Card.Content>
                                                    <Card.Header>
                                                        {resource.label}
                                                        {resource.logo && <Image floated="right" size='mini' src={resource.logo} />}
                                                        <span className="ComponentMetaRow">
                                                            {resource.documentationLink && <a target="_blank" rel="noreferrer" href={resource.documentationLink}>Documentation</a>}
                                                            {resource.video && <a target="_blank" rel="noreferrer" href={resource.video}>Video</a>}
                                                            {resource.supportLink && <a target="_blank" rel="noreferrer" href={resource.supportLink}>Support</a>}
                                                        </span>
                                                    </Card.Header>
                                                    <Card.Meta>
                                                        <StateLabel state={resource.state} />
                                                    </Card.Meta>
                                                </Card.Content>
                                                <Card.Content extra>
                                                    <div className={resource.launchUrl ? 'ui two buttons' : 'ui'}>
                                                        {resource.launchUrl &&
                                                            <>
                                                                {resource.deploymentData?.launchUriIsFlows ?
                                                                    <LaunchButton className='cta' url={resource.launchUrl} label='Flows' /> :
                                                                    resource.deploymentData?.launchUriIsDownload ?
                                                                        <LaunchButton className='cta' url={resource.launchUrl} label='Download' /> :
                                                                        <LaunchButton url={resource.launchUrl} />}
                                                            </>}
                                                        <Button className="" onClick={() => handleOnClick(`/demo/${demo.name}/resources/${resource.resourceId}`)}>
                                                            Manage
                                                        </Button>
                                                    </div>
                                                </Card.Content>
                                            </Card>
                                        )
                                        }

                                        {idp && idp.type === 'customer-identity' && flags.insights_static_resource.enabled &&
                                            <Card key='Insights'>
                                                <Card.Content>
                                                    <Card.Header>Insights</Card.Header>
                                                    <Card.Meta>
                                                        Showcase CIC's ability to integrate with log streaming services. In this app, you'll be able to show security personas how CIC can surface security event data.
                                                    </Card.Meta>
                                                </Card.Content>
                                                <Card.Content extra>
                                                    <div className='ui two buttons'>
                                                        <Button className="branded" onClick={() => window.open('https://insights.demo.okta.com/')}>
                                                            Launch
                                                        </Button>
                                                    </div>
                                                </Card.Content>
                                            </Card>
                                        }
                                    </CardGroup>
                                }
                            </Container>
                        )}
                    </Container>
                ) : (
                    <Loader active inline />
                )}
            </Segment>
        </Container>
    )
}
export default DemoDetails;